<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.UserInfoUserName" placeholder="姓名"></el-input>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <el-table-column label="姓名" prop="UserInfoUserName"></el-table-column>
        <el-table-column label="岗位" prop="Post"></el-table-column>
        <el-table-column label="转正日期" prop="StartDate" :formatter="formatStartTime"></el-table-column>
        <el-table-column label="转正工资执行日期" prop="SalaryDate" :formatter="formatStartTime"></el-table-column>
        <el-table-column label="薪资类型" prop="SalaryType"></el-table-column>
        <el-table-column label="职务待遇" prop="PostSalary"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper ,total" :page-count="total" :total="totaldata">
    </el-pagination>
    <el-dialog :title="operation ? '合同录入' : '编辑'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
      <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" :disabled="true" v-model="SakaryForm.UserName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属类型:" prop="ConTypeName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.ConTypeName" placeholder="合同类型" @change="CertTypeModel($event)" style="width: 100%">
                <el-option v-for="item in CertTypeLists" :key="item.Id" :label="item.Name" :value="item.Name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
            <el-row>
        <el-col :span="12">
            <el-form-item label="归属公司:" prop="AttributionUnit">
                <el-select v-model="SakaryForm.AttributionUnit" placeholder="归属公司" style="width: 100%">
                    <el-option label="格瑞德集团"  value="格瑞德集团"></el-option>
                    <el-option label="山东航安新材料科技有限公司" value="山东航安新材料科技有限公司"></el-option>
                    <el-option label="山东格瑞德集团进出口有限公司" value="山东格瑞德集团进出口有限公司"></el-option>
                    <el-option label="山东格瑞德环保科技有限公司" value="山东格瑞德环保科技有限公司"></el-option>
                    <el-option label="山东酷菱能源科技有限责任公司" value="山东酷菱能源科技有限责任公司"></el-option>
                    <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
                    <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
                    <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
                    <el-option label="淄博格瑞德炭净环保有限公司" value="淄博格瑞德炭净环保有限公司"></el-option>
                    <el-option label="山东欣瑾德贸易有限公司" value="山东欣瑾德贸易有限公司"></el-option>
                    <el-option label="山东格瑞德环保材料科技有限公司" value="山东格瑞德环保材料科技有限公司"></el-option>
                    <el-option label="山东德力特电机有限公司" value="山东德力特电机有限公司"></el-option>
                    <el-option label="德道企服（山东）环境科技有限公司" value="德道企服（山东）环境科技有限公司"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="StartDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm.StartDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间:" prop="EndDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm.EndDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同期限:" prop="ConDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.ConDate"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型:" prop="Type" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.Type" placeholder="所属类型" style="width: 100%">
                <el-option label="新签" value="新签"></el-option>
                <el-option label="续签" value="续签"></el-option>
                <el-option label="解除" value="解除"></el-option>
                <el-option label="终止" value="终止"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label for="ConText" class="el-form-item__label" style="width: 100px">合同内容:</label>
            <el-input type="textarea" v-model="SakaryForm.ConText" style="width: 80%">
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 22px">
          <label for="Remark" class="el-form-item__label" style="width: 100px">备注:</label>
          <el-input type="textarea" v-model="SakaryForm.Remark" style="width: 80%">
          </el-input>
        </el-row>
        <el-row style="margin-top: 22px">
          <el-table :data="SakaryForm.ExaminerArry" style="width: 100%" class="bg-color">
            <el-table-column label="合同类型" prop="ConTypeName"></el-table-column>
            <el-table-column label="合同开始时间" prop="StartDate" :formatter="formatStartTime"></el-table-column>
            <el-table-column label="合同结束时间" prop="EndDate" :formatter="formatStartTime"></el-table-column>
            <el-table-column label="合同期限" prop="ConDate"></el-table-column>
            <el-table-column label="类型" prop="Type"></el-table-column>
          </el-table>
        </el-row>
        <upload-files :files="SakaryForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog title="选择职位" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoosejob :data="choosedusers" :all="true" :key="key" :single="false" @callback="usercallFunction"></UserChoosejob>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/Departmenttool";
import UploadFiles from "../../components/UploadFiles";
import {
  GetPositiveFormalityPageList,
  PositiveApprovalComplete,
  AddContract,
  ContractGetInfoList,
  OfficialOrgInfo,
  GetDicContractTypePageList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      addUserListVisible: false,
      loading: true,
      mianzhi: false,
      renzhi: false,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      CertTypeLists: [],
      ExaminerArry:[],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      PositionLists: [],
      SakaryForm: {
        UserName: "",
        UserId: "",
        ConTypeId: "",
        ConTypeName: "",
        EndDate: "",
        ConDate: "",
        ConText: "",
        Remark: "",
        Type: "",
        StartDate: "",
        FileArry: [],
        ExaminerArry:[],
      },
      SakaryUserForm: {
        UserId: null,
        UserName: null,
        Factory: null,
        Dpt: null,
        Post: null,
        PostId: null,
        optType: null,
      },
      selectForm: {
        userName: "",
        UserInfoUserName:"",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    PersonsDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.Orgs.splice(this.SakaryForm.Orgs.indexOf(row), 1);
      });
    },
    usercallFunction(newdata) {
      console.log(newdata);
      this.SakaryForm.Orgs.push({ DptName: newdata.Name, DptId: newdata.Id });

      this.addUserVisible = false;
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    CertTypeModel(id) {
      let obj = {};
      obj = this.CertTypeLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.Name === id; //筛选出匹配数据
      });
      console.log(obj);
      this.SakaryForm.ConTypeId = obj.Id;
      this.SakaryForm.ConTypeName = obj.Name;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },

    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.UserInfoUserName,
      };

      GetPositiveFormalityPageList(para).then((res) => {
        console.log(para);
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      OfficialOrgInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      OfficialPersonInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    handleExamine() {//确认
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要确认的一行数据！",
          type: "error",
        });
        return;
      }
      PositiveApprovalComplete({ id: row.Id }).then((res) => {
        console.log(res);
        if (res.data.success) {
            this.$message({
                message: "更新成功",
                type: "success",
                duration: 5000,
            });
            this.getData();
        } else {
          this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
          });
        }
      });
    },
    handleAdd() {//合同录入
    let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择一行数据！",
          type: "error",
        });
        return;
      }
        GetDicContractTypePageList().then((res) => {
            this.CertTypeLists = res.data.response.data;
        });
        this.SakaryForm.UserName=row.UserInfoUserName;
        ContractGetInfoList({ userId:row.UserId }).then((res) => {
            console.log(res)
            this.SakaryForm.ExaminerArry = res.data.response;
        });
        (this.key += 1);
        (this.operation = true), (this.SakaryVisible = true);
        this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
                AddContract(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.Files = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

